import React, {useEffect, useState} from 'react'
import MainPageComponent from './MainPageComponent'
import { useQuery } from '@apollo/client'
import { currentUserInfoQuery } from '../user/Queries'

export default function MainPageContainer(props) {

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const {loading, error, data} = useQuery(currentUserInfoQuery)


  useEffect(() => {

    if (error) {
      setIsLoggedIn(false)
    }
    if (data && data.currentUserInfo.id) {
      setIsLoggedIn(true)
    }
  }, [error, data])

  if (loading) {
    return (<div />)
  }


  let myProps = isLoggedIn && data ? {
    ...props,
    ...data.currentUserInfo,
    isLoggedIn,
  } :{
    ...props,
    isLoggedIn,
  }
  console.log(myProps)

  return MainPageComponent(myProps)
}