import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import MainPageContainer from './mainpage/MainPageContainer'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context'


const link = createHttpLink({
    uri: '/graphql',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  console.log("token: ", token)
  return {
    headers: {
      ...headers,
      authorization: !!token ? `Bearer ${token}` : "",
    }
  }
})

const client = new ApolloClient( {
    link: authLink.concat(link),
    cache: new InMemoryCache(),
})

const Root = () => {
    return (
        <ApolloProvider client={client}>
            <MainPageContainer />
        </ApolloProvider>
    )
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
