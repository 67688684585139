import React from 'react'
import {GoogleLogout} from 'react-google-login'


export default function UserSignedInComponent(props) {

  const clientId = process.env.REACT_APP_CLIENT_ID

  return (
    <div style={{ display: 'flex'}}>
      <div>
        <ul className="collection">
          <li className="collection-item" key="naam">Naam: {props.profile.name}</li>
          <li className="collection-item" key="level">Level: {props.profile.level}</li>
          <li className="collection-item" key="digsites">Opgraving Locaties: {props.profile.digsites ? props.profile.digsites.length() : 0}</li>
          <li className="collection-item" key="fossielen">Fosielen: {props.profile.fossielen ? props.profile.fossielen.length(): 0}</li>
          <li className="collection-item" key="eggs">Dino-eieren: {props.profile.eggs ? props.profile.eggs.length(): 0}</li>
          <li className="collection-item" key="dinos">Dinos: {props.profile.dinos ? props.profile.dinos.length(): 0}</li>
        </ul>
      </div>
    <div style={{ display: 'flex', flexDirection:'column'}} >
      <img height="200" width="200" src={props.profile.picture} alt="avatar"  className="circle" referrerPolicy="no-referrer" />
      <GoogleLogout clientId={clientId} onLogoutSuccess={props.googleLogoutSuccess} />
    </div>
    </div>
  )
}