import { gql } from '@apollo/client'

export const currentUserInfoQuery = gql`
{
    currentUserInfo {
        id
        name
        picture
        level
    }
}
`