import {useState} from 'react'
import UserSignedOutComponent from './UserSignedOutComponent'
import UserSignedInComponent from './UserSignedInComponent'
import { useQuery } from '@apollo/client'
import { currentUserInfoQuery } from '../user/Queries'


export default function UserContainer(props) {

  const [loggedIn, setLoggedIn] = useState(false)
  
  const { client, data , refetch } = useQuery(currentUserInfoQuery)

  function googleLoginSuccess(response) {
    localStorage.setItem('token', response.accessToken)
    setLoggedIn(true)
    refetch().catch(error => console.log(error))
  }

  function googleLogoutSuccess() {
    localStorage.removeItem('token')
    setLoggedIn(false)
    client.resetStore()
  }

  function googleLoginFailure(error) {
    localStorage.removeItem('token')
    setLoggedIn(false)
    client.resetStore()
  }


  let componentProps = {
    ...props,
    profile: data && data.currentUserInfo ? data.currentUserInfo : {},
    googleLoginSuccess,
    googleLoginFailure,
    googleLogoutSuccess,
  }


  if (loggedIn) {
    return UserSignedInComponent(componentProps)
  } else {
    return UserSignedOutComponent(componentProps)
  }
}