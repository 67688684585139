
import React, { useEffect } from 'react'
import UserContainer from '../user/UserContainer'
import ReactGlobe from 'react-globe'

import {SVGLoader} from 'three'

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

export default function BaseInterfaceComponent(props) {


  function toMaxFixed(value, dp) {
    return +parseFloat(value).toFixed( dp )
  }

  let  toolTipProvider = (marker) => {
    return `Opgraving: ${marker.naam} (${toMaxFixed(100*marker.huidigeHoeveelheid/marker.oorspronkelijkeHoeveelheid, 2)}%)`
  }

  const options = {
    cameraAutoRotateSpeed: 0,
    markerTooltipRenderer: toolTipProvider,
    markerType: 'dot'
  }

  let markers = [
    {
      id: '123',
      naam: 'Scollard',
      huidigeHoeveelheid: 9,
      oorspronkelijkeHoeveelheid: 21,
      qualiteit: 'laag',
      color: 'brown',
      coordinates: [51.906399, -113.0289],
      value: 9
    },
  ]

    return (
      <div style={{height: '100vh', padding: 0, margin: 0}}>
        <div style={{height: '50%', width: '50%', float: 'left'}}><ReactGlobe markers={markers} options={options} /></div>
        <div style={{height: '50%', width: '50%', float: 'left'}} className="green lighten-2">Dinos</div>      
        <div style={{height: '50%', width: '50%', float: 'left'}} className="green lighten-4">Eggs & Fossils</div>
        <div style={{height: '50%', width: '50%', float: 'left'}} className="light-green"><UserContainer/></div>
      </div>
    )
}