import React from 'react'
import BaseInterfaceContainer from '../BaseInterface/BaseInterfaceContainer'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import FaultyLocationComponent from '../FaultyLocation/FaultyLocationComponent'

export default function MainPageComponent(props) {

    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <BaseInterfaceContainer/>
          </Route>
          <Route>
            <FaultyLocationComponent/>
          </Route>
        </Switch>
      </Router>
    )
}